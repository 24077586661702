import moment from "moment";

export const projectName = "Shubham Artesia";
export const serverErrorMessage = "Something Went Wrong!";

export const custTime = "9 AM";

export const selectableTimes = ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"];

export const scheduleConfUrl = "public_api/leads";
export const bookingUrl = "public/bookings/new";
export const userTypeUrl = "public/user_type";
export const enquiryFormUrl = "public/customer";

export const paymentPlans = ["CLP", "EMI Scheme", "EMI+CLP", "Subvention 1:99", "Subvention 10:90"];

export function confCallParams(callParams) {
	let toSend = {};
	toSend["name"] = callParams.custName;
	toSend["email"] = callParams.custEmail;
	toSend["phone"] = callParams.custPhone;
	toSend["date"] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null;
	toSend["time"] = callParams.custTime;
	toSend["project"] = projectName;
	return toSend;
}

export function santizeBookingParams(bParams) {
	let toSend = {};
	if (bParams.leadNo) {
		toSend["ln"] = bParams.leadNo;
	} else {
		toSend["p"] = projectName;
	}
	toSend["t"] = bParams.typologyName;
	toSend["f"] = bParams.floorName;
	toSend["in"] = bParams.flatName;
	toSend["wi"] = bParams.wingName;
	toSend["pn"] = bParams.selectedPlan;
	toSend["cpuu"] = bParams.cpId;
	return toSend;
}

export const masterJson = {
	Buildings: [
		{
			id: "A1",
			path: "M466 335H416V365H369.5V299H312V322.5H279.5H251.5V335H221V377V465H285.5H312H336.5H373.5H466V335Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B7",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A2",
			path: "M222 335.5H194.5V358.5H168.5V284H142V260H55V323H36V373H47V483H106.5V473.5H130H159H222V335.5Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B8",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A3",
			path: "M222 185H194.5V162H168.5V236.5H142V260.5H55V197.5H36V147.5V42H47H65H82H168.5V62.5H222V185Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B9",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A4",
			path: "M388.5 161.5V209.5H363H332.5V190.5H281V161.5H256.5H239.5H223V140.5V35H234H252H269H355.5H455V161.5H388.5Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B10",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b1",
			path: "M752.5 44V75V106.5V135H716.5V169H665V143H592V127V116.5V106.5V94V75V56V23H639H708.5H729H752.5V44Z",
			config: "2bhk",
			wing: "B",
			fill: "#0499B11",
			opacity: "0.01",
			value: "2BHK",
		},
		{
			id: "b2",
			path: "M941.5 160H929V221H863.5H791V162H778V134.5H753V128V117.5V107.5V95V76V57V24H800H869.5V17H941.5V160Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B12",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b3",
			path: "M940 332.5H920.5V397.5H904H865.5V460.5H815.5V450H754V364.5H739V332.5H770.5V306.5H790.5V222H837.5H928.5V282H940V332.5Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B13",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b4",
			path: "M754.5 362V461.5H614H607H589.5V472H531.5H502H486.5H470V440.5V335H481H499H516H602.5H702V362H754.5Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B14",
			opacity: "0.01",
			value: "3BHK",
		},
	],
	units: [
		{
			id: "2",
			floor: "2",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "3",
			floor: "3",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "4",
			floor: "4",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "5",
			floor: "5",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "6",
			floor: "6",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "7",
			floor: "7",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M468 328.687H414.939V361.759H365.592V289H300.647V314.907H270.082H240.367V328.687H208V374.988V472H276.449H304.571H330.571H369.837H468V328.687Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M207.945 331.357H178.088V356.141H150.859V275.862H121.087V250H26.6288V317.887H6V371.765H17.943V490.298H82.5437V480.061H108.058H139.544H207.945V331.357Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M210.078 168.275H178.87V143.301H150.365V224.196H119.292V250.256H26.5618V183.848H3V15.9999H149.365V35.2597H210.078V168.275Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M388.058 149.713V201.588H326.82V171H270.503V147.713H207.078V12.9999H460.778V149.713H388.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "8",
			floor: "8",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/8-A.png",
			width: "480",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M206.078 336.863H176.221V361.648H147.992V281.369H119.22V255.507H24.7621V323.394H4.1333V377.272H16.0763V495.805H80.677V485.568H106.192H137.678H206.078V411.216V336.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M207 173.762H176.691V148.709H148.035V229.858H118.828V256H22.9409V187.378H2V132.915V18H14.1237H33.9624H52.6989H148.035V40.3295H207V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M387.058 147.866V199.742H359.173H325.82V179.208H269.503V147.866H242.711H224.121H206.078V125.171V11.1532H218.107H237.791H256.381H350.971H459.778V147.866H387.058Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "9",
			floor: "9",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-A.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V364.578H360.561V295H300.865V319.774H270.888H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208.078 332.863H178.221V357.648H149.992V277.369H121.22V251.507H26.7621V319.394H6.1333V373.272H18.0763V491.805H82.677V481.568H108.192H139.678H208.078V332.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V256H23.043V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V40.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 148.011V200H355.845H323.636V179.421H269.25V148.011H243.377H225.425H208V125.266V11H219.616H238.625H256.578H347.925H453V148.011H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "10",
			floor: "10",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-A.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V364.578H360.561V295H300.865V319.774H270.888H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208.078 332.863H178.221V357.648H149.992V277.369H121.22V251.507H26.7621V319.394H6.1333V373.272H18.0763V491.805H82.677V481.568H108.192H139.678H208.078V332.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V256H23.043V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V40.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 148.011V200H355.845H323.636V179.421H269.25V148.011H243.377H225.425H208V125.266V11H219.616H238.625H256.578H347.925H453V148.011H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "11",
			floor: "11",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-A.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V364.578H360.561V295H300.865V319.774H270.888H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208.078 332.863H178.221V357.648H149.992V277.369H121.22V251.507H26.7621V319.394H6.1333V373.272H18.0763V491.805H82.677V481.568H108.192H139.678H208.078V332.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V256H23.043V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V40.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 148.011V200H355.845H323.636V179.421H269.25V148.011H243.377H225.425H208V125.266V11H219.616H238.625H256.578H347.925H453V148.011H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "12",
			floor: "12",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-A.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V364.578H360.561V295H300.865V319.774H270.888H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208.078 332.863H178.221V357.648H149.992V277.369H121.22V251.507H26.7621V319.394H6.1333V373.272H18.0763V491.805H82.677V481.568H108.192H139.678H208.078V332.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V256H23.043V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V40.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 148.011V200H355.845H323.636V179.421H269.25V148.011H243.377H225.425H208V125.266V11H219.616H238.625H256.578H347.925H453V148.011H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "13",
			floor: "13",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-A.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V364.578H360.561V295H300.865V319.774H270.888H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208.078 332.863H178.221V357.648H149.992V277.369H121.22V251.507H26.7621V319.394H6.1333V373.272H18.0763V491.805H82.677V481.568H108.192H139.678H208.078V332.863Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V256H23.043V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V40.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 148.011V200H355.845H323.636V179.421H269.25V148.011H243.377H225.425H208V125.266V11H219.616H238.625H256.578H347.925H453V148.011H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "14",
			floor: "14",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-A.png",
			width: "475",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V294.578H364.561V295H300.865H267.888V319.774H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208 331.256H178.43V356.009H150.473V281.83H121.979V256H8V298.44V323.803V377.614V454.746L19.828 454.746V496H83.8065V485.776H109.075H140.258H208V331.256Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V253H9.04301V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V21.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 144.387V195H355.845H323.636V174.966H269.25V144.387H243.377H225.425H208V122.244V11H219.616H238.625H256.578H347.925H453V144.387H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "15",
			floor: "15",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/15-A.png",
			width: "467",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M209 330.628H179.43V355.176H151.473V281.614H122.979V256H9V298.087V323.238V376.601V453.09L20.828 453.09V494H84.8065H98H122.979H142H209V330.628Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M209 172.413H178.395V146.934H151.46V229.464H122.968V253H9.0772V186.261H2V130.871V14H14.2419H34.2742H53.1936H149.46V17.3862H209V172.413Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M386.627 143.562V195H359.259H326.524V174.639H271.25V143.562H244.955H226.709H209V121.057V8H220.806H240.125H258.371H351.209H458V143.562H386.627Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "16",
			floor: "16",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-A.png",
			width: "475",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V294.578H364.561V295H300.865H267.888V319.774H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208 331.256H178.43V356.009H150.473V281.83H121.979V256H8V298.44V323.803V377.614V454.746L19.828 454.746V496H83.8065V485.776H109.075H140.258H208V331.256Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V253H9.04301V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V21.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 144.387V195H355.845H323.636V174.966H269.25V144.387H243.377H225.425H208V122.244V11H219.616H238.625H256.578H347.925H453V144.387H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "17",
			floor: "17",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-A.png",
			width: "475",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V294.578H364.561V295H300.865H267.888V319.774H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208 331.256H178.43V356.009H150.473V281.83H121.979V256H8V298.44V323.803V377.614V454.746L19.828 454.746V496H83.8065V485.776H109.075H140.258H208V331.256Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V253H9.04301V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V21.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 144.387V195H355.845H323.636V174.966H269.25V144.387H243.377H225.425H208V122.244V11H219.616H238.625H256.578H347.925H453V144.387H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "18",
			floor: "18",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-A.png",
			width: "475",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M465 332.952H412.959V294.578H364.561V295H300.865H267.888V319.774H241.745V332.952H210V377.229V470H277.133H304.714H330.214H368.724H465V332.952Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M208 331.256H178.43V356.009H150.473V281.83H121.979V256H8V298.44V323.803V377.614V454.746L19.828 454.746V496H83.8065V485.776H109.075H140.258H208V331.256Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.762H177.543V148.709H148.747V229.858H119.398V253H9.04301V187.378H2V132.915V18H14.1828H34.1183H52.9462H148.747V21.3295H208V173.762Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M382.774 144.387V195H355.845H323.636V174.966H269.25V144.387H243.377H225.425H208V122.244V11H219.616H238.625H256.578H347.925H453V144.387H382.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "19",
			floor: "19",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/19-A.png",
			width: "457",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "M442 329.531H392.408V293H346.288V293.401H285.589H254.164V316.986H229.251V329.531H199V371.683V460H262.973H289.257H313.557H350.255H442V329.531Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M197 327.806H168.761V351.425H142.062V280.646H114.849V256H6V296.495V320.695V372.04V445.637L17.2957 445.637V485H78.3952V475.244H102.527H132.306H197V327.806Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M197 177.471H167.874V153.591H140.336V230.941H112.269V253H7V208.746L7.00005 190.449V164.493V138.536V29H11.6506H30.7151H48.7205H140.336V32.1737H197V177.471Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "M363.927 149.587V198H338.207H307.444V178.837H255.5V149.587H230.789H213.642H197V128.407V22H208.095H226.25H243.397H330.642H431V149.587H363.927Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "20",
			floor: "20",
			building: "A",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/20-A.png",
			width: "482",
			height: "510",
			paths: [
				{
					id: "A1",
					path: "",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A2",
					path: "M209 329.942H179.578V354.592H151.761V280.722V252H123.409H10V297.264V322.52V376.108V452.918L21.7688 452.918V494H85.4274V483.818H110.57H141.597H209V329.942Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A3",
					path: "M208 173.436H177.691V148.597H149.035V229.055V252H119.828H10.0088V186.936H10V132.937V19H15.1237H34.9624H53.6989H149.035V22.3012H208V173.436Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "A4",
					path: "",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "2",
			floor: "2",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "3",
			floor: "3",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "4",
			floor: "4",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "5",
			floor: "5",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "6",
			floor: "6",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "7",
			floor: "7",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/2-7-B.png",
			width: "509",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M134.5 32.5H300.5V150H262.5V183H210V154.5H134.5V32.5Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M495.5 174.5H481.5L481.5 231.978H427.409H340V174.5H327.5V146.5H300.5V32.5H422V25.5H495.5V174.5Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M495.562 350.111H474.5V417.812H417V483.429H374.716V472.493H301.5V383.44H287.5V350.111H319V323.031H339.5V235.02H481V301.5H495.562V350.111Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M301.5 383.5V489.5L160.91 491.038H153.525H135.064V502.694H9V350.606H245.5V383.5H301.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "8",
			floor: "8",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/8-B.png",
			width: "504",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M300.169 48.0917V80.169V112.764V142.254H262.411V177.436H208.396V150.532H131.831V133.976V123.111V112.764V99.8293V80.169V60.5087V26.362H181.126H254.02H275.521H300.169V48.0917Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M501.972 169.334H488.59V234.215H418.467H340.851V171.461H326.933V142.212H300.169V135.298V124.13V113.494V100.199V79.9902V59.7814V24.682H350.486H424.891V17.2367H501.972V169.334Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M500.93 352.348H480.073V420.048H462.425H421.246V485.666H367.767V474.73H301.987V385.677H285.944V352.348H319.635V325.267H341.027V237.256H391.297H488.629V299.749H500.93V352.348Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "9",
			floor: "9",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-B.png",
			width: "499",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H475.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H472.241V406H482L475.5 425L463.366 441.5L440 463.5L421.5 472L402.346 478H346.873V467.038H293V370.5H276V344.365H296.948V317.22H319.137V229H371.281H472.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 369.168V476.657H149.784H142.649H124.81V488H65.6889H35.6186H19.819H3V453.971V340H14.2127H32.5606H49.8893H138.062H239.485V369.168H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "10",
			floor: "10",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-B.png",
			width: "499",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H475.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H472.241V406H482L475.5 425L463.366 441.5L440 463.5L421.5 472L402.346 478H346.873V467.038H293V370.5H276V344.365H296.948V317.22H319.137V229H371.281H472.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 369.168V476.657H149.784H142.649H124.81V488H65.6889H35.6186H19.819H3V453.971V340H14.2127H32.5606H49.8893H138.062H239.485V369.168H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "11",
			floor: "11",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-B.png",
			width: "499",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H475.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H472.241V406H482L475.5 425L463.366 441.5L440 463.5L421.5 472L402.346 478H346.873V467.038H293V370.5H276V344.365H296.948V317.22H319.137V229H371.281H472.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 369.168V476.657H149.784H142.649H124.81V488H65.6889H35.6186H19.819H3V453.971V340H14.2127H32.5606H49.8893H138.062H239.485V369.168H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "12",
			floor: "12",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-B.png",
			width: "499",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H475.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H472.241V406H482L475.5 425L463.366 441.5L440 463.5L421.5 472L402.346 478H346.873V467.038H293V370.5H276V344.365H296.948V317.22H319.137V229H371.281H472.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 369.168V476.657H149.784H142.649H124.81V488H65.6889H35.6186H19.819H3V453.971V340H14.2127H32.5606H49.8893H138.062H239.485V369.168H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "13",
			floor: "13",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/9-13-B.png",
			width: "499",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H475.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H472.241V406H482L475.5 425L463.366 441.5L440 463.5L421.5 472L402.346 478H346.873V467.038H293V370.5H276V344.365H296.948V317.22H319.137V229H371.281H472.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 369.168V476.657H149.784H142.649H124.81V488H65.6889H35.6186H19.819H3V453.971V340H14.2127H32.5606H49.8893H138.062H239.485V369.168H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "14",
			floor: "14",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-B.png",
			width: "503",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H492.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H477.241V406L489 413L482.5 432L470.366 448.5L447 470.5L428.5 479L409.346 485V478H346.873H293V467.038V377.5H281V347.365H311.948V317.22H319.137V229H371.281H492.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 377.38V481.964H149.784H142.649H124.81V493H65.6889H35.6186H19.819H3V459.891V349H14.2127H32.5606H49.8893H138.062H239.485V377.38H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "15",
			floor: "15",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/15-B.png",
			width: "489",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M290 46.5753V78.4247V110.788V140.068H254.112V175H202.773V148.288H130V131.849V121.062V110.788V97.9452V78.4247V58.9041V25H176.854H246.137H266.573H290V46.5753Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M485.185 166.678H489V226H404.419H315.887V195.292V168.785V139.809H290V132.96V121.896V111.359V98.1879V78.1677V58.1476V23.3758H338.667H404.717V16H485.185V166.678Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M481.801 340.013H474.088V400.926L485.778 407.844L479.316 426.621L467.253 442.928L444.025 464.67L425.633 473.07L406.592 479V472.082H344.486H290.929V461.248V372.76H279V342.978H309.766V313.186H316.913V226H368.751H489V287.907H481.801V340.013Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M291 372.985V476.117H149.266H142.204H124.55V411.5H210V399.5V387V378.985V368.5L210 364.5L210 358V353V345H225H238.039V372.985H291Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "16",
			floor: "16",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-B.png",
			width: "503",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H492.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H477.241V406L489 413L482.5 432L470.366 448.5L447 470.5L428.5 479L409.346 485V478H346.873H293V467.038V377.5H281V347.365H311.948V317.22H319.137V229H371.281H492.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 377.38V481.964H149.784H142.649H124.81V493H65.6889H35.6186H19.819H3V459.891V349H14.2127H32.5606H49.8893H138.062H239.485V377.38H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "17",
			floor: "17",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-B.png",
			width: "503",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H492.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H477.241V406L489 413L482.5 432L470.366 448.5L447 470.5L428.5 479L409.346 485V478H346.873H293V467.038V377.5H281V347.365H311.948V317.22H319.137V229H371.281H492.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 377.38V481.964H149.784H142.649H124.81V493H65.6889H35.6186H19.819H3V459.891V349H14.2127H32.5606H49.8893H138.062H239.485V377.38H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "18",
			floor: "18",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/14-18-B.png",
			width: "503",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M291 47.7192V79.7808V112.36V141.836H254.888V177H203.227V150.11H130V133.562V122.702V112.36V99.4315V79.7808V60.1301V26H177.146H246.863H267.427H291V47.7192Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M489 169.113H492.87V229H407.069H317.26V198V171.24V141.988H291V135.074V123.904V113.267V99.9706V79.7598V59.549V24.4461H340.369H407.371V17H489V169.113Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M485 344.365H477.241V406L489 413L482.5 432L470.366 448.5L447 470.5L428.5 479L409.346 485V478H346.873H293V467.038V377.5H281V347.365H311.948V317.22H319.137V229H371.281H492.241V291.642H485V344.365Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M293 377.38V481.964H149.784H142.649H124.81V493H65.6889H35.6186H19.819H3V459.891V349H14.2127H32.5606H49.8893H138.062H239.485V377.38H293Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "19",
			floor: "19",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/19-B.png",
			width: "478",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "M276 54.5685V84.9315V115.784V143.699H241.682V177H192.589V151.534H123V135.863V125.579V115.784V103.541V84.9315V66.3219V34H167.804H234.056H253.598H276V54.5685Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M463.338 169.22H467V226H385.819H300.846V196.608V171.237V143.503H276V136.947V126.357V116.272V103.666V84.5034V65.3413V32.0597H322.71H386.105V25H463.338V169.22Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M460.11 335.056H452.727V393.32L463.916 399.938L457.731 417.898L446.185 433.496L423.952 454.293L406.349 462.328L388.123 468V461.383H328.68H277.418V451.02V366.379H266V337.892H295.447V309.396H302.288V226H351.904H467V285.216H460.11V335.056Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "M277 366.803V465.577H141.685H134.944H118.09V476H62.2302H33.819H18.891H3V444.73V340H13.594H30.9297H47.3023H130.61H226.438V366.803H277Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "20",
			floor: "20",
			building: "B",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floorplans/20-B.png",
			width: "502",
			height: "510",
			paths: [
				{
					id: "B1",
					path: "",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B2",
					path: "M486.166 173.983H490V235H404.994H316.017V203.415V176.15V146.346H290V139.301V127.921V117.084V103.536V82.944V62.3518V26.5866H338.911H405.293V19H486.166V173.983Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B3",
					path: "M482.801 349.013H475.088V409.926L486.778 416.844L480.316 435.621L468.253 451.928L445.025 473.67L426.633 482.07L407.592 488V481.082H345.486H291.929V470.248V381.76H280V351.978H310.766V322.186H317.913V235H369.751H490V296.907H482.801V349.013Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "B4",
					path: "",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
	],
	Variants: [
		{
			id: "A1",
			key: "A1",
			value: "3bhk",
			wing: "A",
			configuration: "3BHK",
			paths: [
				{
					path: "M466 351.12H418.5V326V311H364.773H304.457H270.365V337.19H240.994V351.12H209V397.928V489H276.659H304.457H330.157H368.969H466V351.12Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "A2",
			key: "A2",
			value: "3bhk",
			wing: "A",
			configuration: "3BHK",
			paths: [
				{
					path: "M207 511.753V352.5H179V377.5H150V298.5H126V273H111.538H14.2475V352.5H5.30107L5.30127 377.5V476.5H22.0002V511.753H71.8389H98.1185H130.549H207Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "A3",
			key: "A3",
			value: "3bhk",
			wing: "A",
			configuration: "3BHK",
			paths: [
				{
					path: "M208 195.919H179.169V171.806H151.911V249.911H124.129V272.5H33.5H13V209.024V156.605V46H24.5323H43.4032H61.2258H151.911H208V67.4919V195.919Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "A4",
			key: "A4",
			value: "3bhk",
			wing: "A",
			configuration: "3BHK",
			paths: [
				{
					path: "M381.774 168.312V217H354.845H322.636V197.728H268.25V168.312H242.377H224.425H207V147.011V40H218.616H237.625H255.578H346.925H452V168.312H381.774Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "B1",
			key: "B1",
			value: "2bhk",
			wing: "B",
			configuration: "2BHK",
			paths: [
				{
					path: "M760 48.7192V80.7808V113.36V142.836H722.766V178H669.502V151.11H594V134.562V123.702V113.36V100.432V80.7808V61.1301V27H642.611H714.492H735.695H760V48.7192Z",
					fill: "#0499B11",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "B2",
			key: "B2",
			value: "3bhk",
			wing: "B",
			configuration: "3BHK",
			paths: [
				{
					path: "M959 16V164.69V228.117H877.897H788.995V183.5V166.769V138.175H763V131.416V120.499V110.101V97.1034V77.3475V57.5915V23.2785H811.87H884.135V16H959Z",
					fill: "#0499B12",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "B3",
			key: "B3",
			value: "3bhk",
			wing: "B",
			configuration: "3BHK",
			paths: [
				{
					path: "M961 353.495H950.5V415.5H957L954 427L947 441.5L935 458L922 470L902 482.687L877.5 490H818.537V482.687H760V379.5H745.5V350H805V320.5H789.93V232H843.711H947.841H961V297.97V353.495Z",
					fill: "#0499B13",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "B4",
			key: "B4",
			value: "3bhk",
			wing: "B",
			configuration: "3BHK",
			paths: [
				{
					path: "M759 380.168V487.657H615.784H608.649H590.81V499H531.689H501.619H485.819H469V464.971V351H480.213H498.561H515.889H604.062H705.485V380.168H759Z",
					fill: "#0499B14",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
	],
};

export const FormSteps = ["Explore Project", "Explore Master layout", "Select Variant", "Check Details", "KYC & Make Payment"];
